import React from 'react'
import { Provider } from 'mobx-react'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'
import theme from './ui/content/style/materialUI/theme'
import ScreenLoading from './ui/components/shared/Loading/ScreenLoading'
import * as styled from './App.styled'
import rootStore from './stores/index'
import Notifications from './ui/components/shared/Notifications/Notifications'
import Modals from './ui/components/shared/Modals/Modals'
import Reports from './ui/pages/Reports/Reports'
import DetailedSearch from './ui/pages/Administration/DetailedSearch/DetailedSearch'
import Home from './ui/pages/Home/Home'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ClerkLoaded, ClerkProvider } from '@clerk/clerk-react'
import { BetaSignIn } from 'ui/pages/UserFlow/BetaSignIn'

const UserFlow = React.lazy(() => import('./ui/pages/UserFlow/UserFlow'))
const InviteUsers = React.lazy(() => import('./ui/pages/Administration/InviteUsers/InviteUsers'))
const MapDemo = React.lazy(() => import('./ui/pages/Administration/MapDemo/MapDemo'))
const TemplatesAdmin = React.lazy(() => import('./ui/pages/Administration/TemplatesAdmin/TemplatesAdmin'))
const Templates = React.lazy(() => import('./ui/pages/Templates/Templates'))
const AccountUnlock = React.lazy(() => import('./ui/pages/Administration/AccountUnlock/AccountUnlock'))
const ListCommunications = React.lazy(() => import('./ui/pages/Advanced/ListCommunications/ListCommunications'))
const AudienceLists = React.lazy(() => import('./ui/pages/Advanced/AudienceLists/AudienceLists'))
const EditAudienceList = React.lazy(() => import('./ui/pages/Advanced/AudienceLists/EditAudienceList'))
const DynamicLists = React.lazy(() => import('./ui/pages/Advanced/DynamicLists/DynamicLists'))
const DynamicListsMapping = React.lazy(() => import('./ui/pages/Advanced/DynamicLists/Mapping'))
const DynamicList = React.lazy(() => import('./ui/pages/Advanced/DynamicLists/List'))
const AddTemplateOrConfig = React.lazy(() => import('./ui/pages/Advanced/ListCommunications/AddTemplateOrConfig'))
const ListCommunicationEditor = React.lazy(() => import('./ui/pages/Advanced/ListCommunications/ListCommunicationEditor'))
const BaseTemplateEditor = React.lazy(() => import('./ui/pages/Advanced/ListCommunications/BaseTemplateEditor'))
const RecurringListMessages = React.lazy(() => import('./ui/pages/Advanced/ListCommunications/RecurringListMessages'))
const Tasks = React.lazy(() => import('./ui/pages/Administration/Tasks/Tasks'))
const Task = React.lazy(() => import('./ui/pages/Administration/Tasks/Task'))
const FailedFiles = React.lazy(() => import('./ui/pages/FailedFiles/FailedFiles'))
const FailedFile = React.lazy(() => import('./ui/pages/FailedFiles/FailedFile'))
const FailedFilesNotifiers = React.lazy(() => import('./ui/pages/FailedFiles/FailedFilesNotifiers'))
const DebugFiles = React.lazy(() => import('./ui/pages/Administration/DebugFiles/DebugFiles'))
const DebugFile = React.lazy(() => import('./ui/pages/Administration/DebugFiles/DebugFile'))
const CommunicationPreferences = React.lazy(() => import('./ui/pages/CommunicationPreferences/CommunicationPreferences'))
const Settings = React.lazy(() => import('./ui/pages/Settings/Settings'))
const CommunicationPreferencesReports = React.lazy(() => import('./ui/pages/CommunicationPreferencesReport/CommunicationPreferencesReport'))
const PasswordReset = React.lazy(() => import('./ui/pages/Administration/PasswordReset/PasswordReset'))
const DownloadableReports = React.lazy(() => import('./ui/pages/Reports/DownloadableReports'))
const Widgets = React.lazy(() => import('./ui/pages/Advanced/AudienceLists/Widgets'))
const PatronRenewal = React.lazy(() => import('./ui/pages/Advanced/PatronRenewal/PatronRenewal'))
const ResetPassword = React.lazy(() => import('./ui/pages/UserFlow/ResetPassword'))
const SmsMessaging = React.lazy(() => import('./ui/pages/SmsMessaging/SmsMessaging'))

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5000
    }
  }
});

declare const CLERK_PUBLISHABLE_KEY: string

const App: React.FC = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <ClerkProvider publishableKey={CLERK_PUBLISHABLE_KEY}>
        <ClerkLoaded>
          <Provider {...rootStore.getStores()}>
            <QueryClientProvider client={queryClient}>
              <styled.AppContainer className="App">
                <React.Suspense fallback={<ScreenLoading />}>
                  <Notifications />
                  <Modals />
                  <Router>
                    <Route exact path="/" render={() => <Redirect to='/home' />} />
                    <Route path="/login" component={BetaSignIn} />
                    <Route path="/legacy-login" exact component={UserFlow} />
                    <Route exact path="/reset-password" component={ResetPassword} />
                    <Route path="/reset-password/:token" component={UserFlow} />
                    <Route path="/create-account/:token" component={UserFlow} />
                    <Route path="/forgot-password" component={UserFlow} />
                    <Route path="/home" component={Home} />
                    <Route exact path="/reports/download" component={DownloadableReports} />
                    <Route exact path="/reports" component={Reports} />
                    <Route path="/templates" component={Templates} />
                    <Route path="/detailedsearch" component={DetailedSearch} />
                    {/* <Route path="/administration/inviteusers" component={InviteUsers} /> */}
                    <Route path="/administration/maps" component={MapDemo} />
                    <Route path="/administration/templates" component={TemplatesAdmin} />
                    {/* <Route path="/administration/accountunlock" component={AccountUnlock} /> */}
                    <Route path="/administration/password-reset" component={PasswordReset} />
                    <Route exact path="/administration/failed-files" component={FailedFiles} />
                    <Route exact path="/administration/failed-file-notifiers" component={FailedFilesNotifiers} />
                    <Route path="/administration/failed-files/:fileId" component={FailedFile} />
                    <Route exact path="/administration/debug-files" component={DebugFiles} />
                    <Route path="/administration/debug-files/:uploadHistoryId" component={DebugFile} />
                    <Route path="/administration/tasks/" component={Tasks} />
                    <Route path="/administration/tasks/:taskId" component={Task} />
                    <Route exact path="/advanced/list-communications" component={ListCommunications} />
                    <Route path="/advanced/list-communications/create" component={AddTemplateOrConfig} />
                    <Route exact path="/advanced/list-communications/create/:baseTemplateId" component={AddTemplateOrConfig} />
                    <Route exact path="/advanced/list-communications/recurring" component={RecurringListMessages} />
                    <Route exact path="/advanced/list-communications/preview/:id" component={ListCommunicationEditor} />
                    <Route exact path="/advanced/list-communications/base/:id" component={BaseTemplateEditor} />
                    <Route exact path="/advanced/audience-lists" component={AudienceLists} />
                    <Route exact path="/advanced/audience-list/edit/:listId" component={EditAudienceList} />
                    <Route exact path="/advanced/dynamic-lists" component={DynamicLists} />
                    <Route exact path="/advanced/dynamic-lists/mapping" component={DynamicListsMapping} />
                    <Route exact path="/advanced/dynamic-list/:id" component={DynamicList} />
                    <Route exact path="/advanced/widgets" component={Widgets} />
                    <Route path="/communication-preferences" component={CommunicationPreferences} />
                    <Route path="/communication-preferences-report" component={CommunicationPreferencesReports} />
                    <Route path="/screenloading" component={ScreenLoading} />
                    <Route path="/settings" component={Settings} />
                    <Route path="/patron-renewal" component={PatronRenewal} />
                    <Route path="/sms-messaging" component={SmsMessaging} />
                    <Route path="/campaigns" render={() => {
                      const path = window.location.pathname
                      window.location.href = `https://beta.messagebee.uniquelibrary.com${path}`
                      return ''
                    }}/>
                  </Router>
                </React.Suspense>
              </styled.AppContainer>
            </QueryClientProvider>
          </Provider>
        </ClerkLoaded>
      </ClerkProvider>
    </MuiThemeProvider>
  )
}

export default App
